/*----------------
Fonts
---------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

/*----------------
Global Css
---------------*/
:root {
  --header-height: ;
  --hue-color: 360;

  /* Color */
  --color-1: hsl(var(--hue-color), 69%, 61%);
  --color-1-second: hsl(var(--hue-color), 69%, 61%);
  --color-1-alt: hsl(var(--hue-color), 57%, 53%);
  --color-1-lighter: hsl(var(--hue-color), 92%, 85%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 45%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  --input-color: hsl(var(--hue-color), 70%, 69%);
  --body-color: hsl(var(--hue-color), 60%, 99%);
  --container-color: #fff;

  /* Font & Typography */
  --body-font: "Poppins", sans-serif;
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /* Font Weight */
  --font-medium: 500;
  --font-semi-bold: 600;

  /* Margin Bottom */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* Z-Index */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Font Size Large Device  */
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/* Base */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: 400;
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* Reuseable CSS Classes */
.section {
  padding: 2rem 0 4rem;
}

.section__title {
  font-size: var(--h1-font-size);
}

.section__subtitle {
  display: block;
  font-size: small;
  margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/* Layout */

.container {
  max-width: 780px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

/* NAV */
.nav {
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-0-25);
  margin-bottom: var(--mb-0-25);
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__logo:hover {
  color: var(--color-1);
}

.nav__toggle {
  font-size: 1.1rem;
  cursor: pointer;
}

.nav__toggle:hover {
  color: var(--color-1);
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--smaller-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__link:hover {
  color: var(--color-1);
}

.nav__icon {
  font-size: 1.2rem;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--color-1);
}

.nav__close:hover {
  color: var(--color-1-alt);
}

/* Show Menu */
.show-menu {
  bottom: 0;
}

/* Active Link */
.active-link {
  color: var(--color-1);
}

/* Change Backgound Header */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/* Home */
.home__container {
  gap: 1rem;
}

.home__content {
  grid-template-columns: 0.5fr 3fr;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--color-1);
}

.home__social-icon:hover {
  color: var(--color-1-alt);
}

.home__blob {
  width: 200px;
  fill: var(--color-1);
}

.home__blob-img {
  width: 500px;
}

.home__data {
  grid-column: 1/3;
}

.home__title {
  font-size: var(--big-font-size);
}

.home__subtitle {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: var(--font-medium);
}

.home__description {
  margin-bottom: var(--mb-2);
}

.home__scroll {
  display: none;
}

.home__scroll-button {
  color: var(--color-1);
}

.home__scroll-button:hover {
  transform: translateY(0.25rem);
}

.home__scroll-mouse {
  font-size: 2rem;
}

.home__scroll-name {
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-25);
}

.home__scroll-arrow {
  font-size: 1.25rem;
}

/* Buttons */
.button {
  display: inline-block;
  background-color: var(--color-1);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: var(--color-1-alt);
}

.button__icon {
  font-size: 1.25rem;
  margin-left: var(--mb-0-5);
  transition: 0.3s;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

.button--small {
  padding: 0.75rem 1rem;
}

/* About */
.about__img {
  width: 200px;
  border-radius: 0.5rem;
  justify-self: center;
  align-self: center;
}

.about__description {
  text-align: center;
  margin-bottom: var(--mb-2-5);
}

.about__info {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--mb-2-5);
}

.about__info-title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.about__info-name {
  font-size: var(--smaller-font-size);
}

.about__info-title,
.about__info-name {
  display: block;
  text-align: center;
}

/* Skills */
.skills__container {
  row-gap: 0;
}

.skills__header {
  display: flex;
  align-items: center;
  margin-bottom: var(--mb-2-5);
}

.skills__icon,
.skills__arrow {
  font-size: 2rem;
  color: var(--color-1);
}

.skills__icon {
  margin-right: var(--mb-0-75);
}

.skills__title {
  font-size: var(--small-font-size);
  color: var(--text-color);
}

.skills__arrow {
  margin-left: auto;
  transition: 0.4s;
}

.skills__list {
  row-gap: 1.5rem;
  padding-left: 2.7rem;
}

.skills__titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--mb-0-5);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.skills__bar,
.skills__percentage {
  height: 5px;
  border-radius: 0.25rem;
}

.skills__bar {
  background-color: var(--color-1-lighter);
}

.skills__percentage {
  display: block;
  background-color: var(--color-1);
}

.skills__html {
  width: 95%;
}

.skills__css {
  width: 80%;
}

.skills__js {
  width: 75%;
}

.skills__php {
  width: 80%;
}

.skills__ejs {
  width: 70%;
}

.skills__firebase {
  width: 65%;
}

.skills__rjs {
  width: 75%;
}

.skills__bs {
  width: 85%;
}

.skills__tw {
  width: 75%;
}

.skills__mysql {
  width: 85%;
}

.skills__ci {
  width: 80%;
}

.skills__server {
  width: 70%;
}

.skills__java {
  width: 70%;
}

.skills__flutter {
  width: 70%;
}

.skills__linux {
  width: 80%;
}

.skills__figma {
  width: 75%;
}

.skills__ink {
  width: 80%;
}

.skills__close .skills__list {
  height: 0;
  overflow: hidden;
}

.skills__open .skills__list {
  max-height: max-content;
  margin-bottom: var(--mb-2-5);
}

.skills__open .skills__arrow {
  transform: rotate(-180deg);
}

/* Qualfication */
.qualification__tabs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--mb-2);
}

.qualification__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.qualification__button:hover {
  color: var(--color-1);
}

.qualification__icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}

.qualification__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.qualification__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.qualification__subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
}

.qualification__calender {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
}

.qualification__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--color-1);
  border-radius: 50%;
}

.qualification__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--color-1);
  transform: translate(6px, -7px);
}

.qualification [data-content] {
  display: none;
}

.qualification__active[data-content] {
  display: block;
}

.qualification__button.qualification__active {
  color: var(--color-1);
}

/* Portfolio */
/* .portfolio__container {
  overflow: initial;
} */

.portfolio__content {
  padding: 0 1.5rem;
}

.portfolio__img {
  width: 265px;
  border-radius: 0.5rem;
  justify-self: center;
}

.portfolio__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-5);
}

.portfolio__description {
  margin-bottom: var(--mb-0-75);
}

.portfolio__button {
  cursor: pointer;
}

.portfolio__button:hover .button__icon {
  transform: translateX(0.25rem);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
}

.swiper-portfolio-icon {
  font-size: 2rem;
  color: var(--color-1);
}

.swiper-button-prev {
  left: -0.5rem;
}

.swiper-button-next {
  right: -0.5rem;
}

.swiper-horizontal > .swiper-pagination-bullets {
  bottom: -0.5rem;
}

.swiper-pagination-bullet-active {
  background-color: var(--color-1);
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullet {
  outline: none;
}

/* Contact Me */
.contact.section {
  min-height: 500px;
}

.contact__container {
  row-gap: 3rem;
}

.contact__information {
  display: flex;
  margin-bottom: var(--mb-2);
}

.contact__icon {
  font-size: 2rem;
  color: var(--color-1);
  margin-right: var(--mb-0-75);
  margin-top: var(--mb-0-75);
}

.contact__title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.contact__subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

/* Footer */
.footer {
  padding-top: 2rem;
}

.footer__container {
  row-gap: 3.5rem;
}

.footer__bg {
  background-color: var(--color-1-second);
  padding: 2rem 0 3rem;
}

.footer__title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-0-25);
}

.footer__subtitle {
  font-size: var(--small-font-size);
}

.footer__links {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.footer__link:hover {
  color: var(--color-1-lighter);
}

.footer__social {
  font-size: 1.25rem;
  margin-right: var(--mb-1-5);
}

.footer__social:hover {
  color: var(--color-1-lighter);
}

.footer__title,
.footer__subtitle,
.footer__link,
.footer__social {
  color: #fff;
}

/* Scroll Up */
.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: var(--color-1);
  opacity: 0.8;
  padding: 0 0.3rem;
  padding-top: 0.4rem;
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

.scrollup:hover {
  background-color: var(--color-1-alt);
}

.scrollup__icon {
  font-size: 1.5rem;
  color: #fff;
}

.show-scroll {
  bottom: 5rem;
}

/* Media Queries */
@media screen and (max-width: 350px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }

  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav__list {
    column-gap: 0;
  }

  .home__content {
    grid-template-columns: 0.25fr 3fr;
  }

  .home__blob {
    width: 180px;
  }

  .skills__title {
    font-size: var(--normal-font-size);
  }

  .qualification__data {
    gap: 0.5rem;
  }
}

@media screen and (min-width: 568px) {
  .home__content {
    grid-template-columns: max-content 1fr 1fr;
  }

  .home__data {
    grid-column: initial;
  }

  .home__img {
    order: 1;
    justify-self: center;
  }

  .about__container,
  .skills__container,
  .portfolio__content,
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .qualification__sections,
  .contact__container {
    display: grid;
    grid-template-columns: 0.6fr;
    justify-content: center;
  }

  .contact.section {
    min-height: 650px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  body {
    margin: 0;
  }

  .section {
    padding: 6rem 0 2rem;
  }

  .section__subtitle {
    margin-bottom: 4rem;
  }

  .header {
    top: 0;
    bottom: initial;
  }

  .header,
  .main,
  .footer__container {
    padding: 0 1rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    column-gap: 1rem;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }

  .nav__list {
    display: flex;
    column-gap: 2rem;
  }

  .nav__menu {
    margin-left: auto;
  }

  .home__container {
    row-gap: 5rem;
  }

  .home__content {
    padding-top: 5.5rem;
    column-gap: 2rem;
  }

  .home__blob {
    width: 270px;
  }

  .home__scroll {
    display: block;
  }

  .home__scroll-button {
    margin-left: 3rem;
  }

  .about__container {
    column-gap: 5rem;
  }

  .about__img {
    width: 270px;
  }

  .about__description {
    text-align: initial;
  }

  .qualification__tabs {
    justify-content: center;
  }

  .qualification__button {
    margin: 0 var(--mb-1);
  }

  .qualification__sections {
    grid-template-columns: 0.5fr;
  }

  .portfolio__container {
    height: 300px;
  }

  .portfolio__img {
    width: 320px;
  }

  .portfolio__content {
    align-items: center;
  }

  .swiper-horizontal > .swiper-pagination-bullets {
    bottom: 0.5rem;
  }

  .footer__container {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer__bg {
    padding: 3rem 0 3.5rem;
  }

  .footer__links {
    flex-direction: row;
    column-gap: 2rem;
    justify-self: center;
  }

  .footer__social {
    justify-self: flex-end;
  }
}

@media screen and (min-width: 1024px) {
  .header,
  .main,
  .footer__container {
    padding: 0;
  }

  .home__blob {
    width: 320px;
  }

  .home__social {
    transform: translateX(-6rem);
  }

  .portfolio__content {
    column-gap: 5rem;
  }

  .swiper-portfolio-icon {
    font-size: 3.5rem;
  }

  .swiper-button-prev {
    left: -0.3rem;
  }

  .swiper-button-next {
    right: -0.3rem;
  }
}
